import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import {TweenMax} from "gsap";
import disableScroll from 'disable-scroll';
import Flickity from "flickity";


$(document).foundation();


$(document).ready(function(){
  // NAV ICON ANIMATION FUNCTION AND DISABLE SCROLL STATEMENT
	$('#nav-icon').click(function(){
    if($(this).hasClass( "open")){
      $(this).removeClass('open');
      disableScroll.off();
      $('#main-menu').css('display','none');
    }
    else{
     $(this).addClass('open');
     $('#main-menu').css('display','block');
     disableScroll.on();
    }
	});
  // -------------------------
  //  FLICKITY SLIDER TESTIMONIALS
  // -------------------------
  if ($(".slider-testimonials").length > 0) {
    const slideTestimonials = new Flickity('.slider-testimonials', {
    contain: true,
    cellAlign: 'center',
    prevNextButtons: false,
    pageDots: true,
    adaptiveHeight: false,
    wrapAround: true,
    });
  }

	// -------------------------
  //  DEEPLINK FIX FOR PERFECT SCROLL POSITION
  // -------------------------
	if ( $(".panel0v-hide")[0] ){
		if ( $('.panel0v-hide').hasClass('is-active') ){
			return;
		} else {
			let $number = $('.quote-container').innerHeight();
			let $scolldown = parseInt($number) + 20
			$(window).scrollTop($scolldown);
		};
	} else {
		return;
	};

});


// LOGO O ANIMATION
function animateO(){
  var tl = new TimelineMax();
    var duration = 1;
    tl.to("#kernInner", duration / 4, {y:-30, ease:Power2.easeOut})
      .to("#kernInner", duration / 1, {y:0, ease:Bounce.easeOut})
      .to("#kernInner", duration / 4, {y:-40, ease:Power2.easeOut}, "+=0.8")
      .to("#kernInner", duration / 1, {y:0, ease:Bounce.easeOut})
      .to("#kernLid", 1, {y: -40, opacity: 0}, "-=1.24");
      return tl;
}

// LOGO LETTERS ANIMATION
function animateENWA(){
  var tl = new TimelineMax();
  tl.staggerFromTo(".lttrGroup", 1, {opacity: 0}, {opacity: 1, ease:Bounce.easeOut}, .1);
  return tl;
}

// LOGO SLOGAN ANIMATION
function animateSlogan(){
  var tl = new TimelineMax();
  tl.to('.sloganSVG', 4.5, {opacity: 1}, {opacity: 1});
  return tl;
}

// MASTER TIMELINE GSAP
var master = new TimelineMax();
master.add(animateO)
      .add(animateENWA, "+=3.4")
      .add(animateSlogan, '+=1.4');


// TABS ON CHANGE SCROLL FUNCTION
$('[data-tabs]').on('change.zf.tabs', function () {
  // e.preventDefault();
  var thisTabContent = $('[data-tabs-content="' + $(this).attr('id') + '"]');
  $('html, body').delay(100).animate({
    scrollTop: thisTabContent.children('.is-active').offset().top - 120
  }, 350);
});

// ACCORDION ON CHANGE SCROLL FUNCTION
//(CHANGE FUNCTION FOR MULTIPLE ACCORDION ITEMS)
$(".accordion-title").click(function() {
		setTimeout(function(){
			$('html,body').animate({scrollTop: $('.accordion-item').offset().top - 120}, 'slow');
		}, 250);
	});


// BACK BUTTON FUNCTION
$('.bottom-nav__back').click(function(){
    window.history.back();
});

function scrollToTop(scrollDuration) {
const   scrollHeight = window.scrollY,
        scrollStep = Math.PI / ( scrollDuration / 15 ),
        cosParameter = scrollHeight / 2;
var     scrollCount = 0,
        scrollMargin,
        scrollInterval = setInterval( function() {
            if ( window.scrollY != 0 ) {
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
            }
            else clearInterval(scrollInterval);
        }, 15 );
}

$('.scrollTop').click(function(){
  scrollToTop(1000);
});
